import React from "react";
import { SectionWrapper, SectionHeader } from "../visuella.style";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <SectionWrapper
      style={{
        "--paddingTop": "120px",
        "--paddingBottom": "60px",
      }}
    >
      <Container>
        <SectionHeader>
          <Heading content={t("termsAndConditions.title")} />
        </SectionHeader>

        {ReactHtmlParser(t("termsAndConditions.content"))}
      </Container>
    </SectionWrapper>
  );
};

export default TermsAndConditions;
