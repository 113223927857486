import React from "react";
import TermsAndConditions from "containers/visuella/TermsAndConditions";

const TermsAndConditionsPage = () => {
  return (
    <>
      <TermsAndConditions />
    </>
  );
};
export default TermsAndConditionsPage;
